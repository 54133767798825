import Vue from "vue";
import VueRouter from "vue-router";
import { redirections } from "@/router/redirections";
import { countries } from "@/store/countries";

Vue.use(VueRouter);

const routes = [
  //redirections

  ...redirections,

  {
    path: "/land23",
    name: "home1",

    component: () =>
      import(/* webpackChunkName: "home" */ "../views/home3.vue"),
    meta: {
      title: "Reliable way to pay and get paid - 1app",
    },
  },
  {
    path: "/",
    children: [
      {
        path: "/cross-border",
        name: "cross-border",

        component: () =>
          import(
            /* webpackChunkName: "cross-border" */ "../views/cross-border/cross-border.vue"
          ),
        meta: {
          title: "Cross Border | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/developer",
        name: "developer",

        component: () =>
          import(
            /* webpackChunkName: "developer" */ "../views/developer/developer.vue"
          ),
        meta: {
          title: "Developer | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/bill-payment",
        name: "bill-payment",

        component: () =>
          import(/* webpackChunkName: "bills" */ "../views/bills/bills.vue"),
        meta: {
          title: "Bill Payment | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/about-us",
        name: "about-us",

        component: () =>
          import(
            /* webpackChunkName: "about-us" */ "../views/about-us/about-us.vue"
          ),
        meta: {
          title: "About US | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/cards",
        name: "cards",

        component: () =>
          import(/* webpackChunkName: "card" */ "../views/card/card.vue"),
        meta: {
          title: "Cards | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/why-us",
        name: "why-us",

        component: () =>
          import(/* webpackChunkName: "why-s" */ "../views/why-us/why-us.vue"),
        meta: {
          title: "Why Us | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/faq",
        name: "faq",

        component: () =>
          import(/* webpackChunkName: "faq" */ "../views/faq.vue"),
        meta: {
          title:
            "Frequently Asked Questions | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/land2",
        name: "home2",

        component: () =>
          import(/* webpackChunkName: "home2" */ "../views/home.vue"),
        meta: {
          title: "Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/cheap",
        name: "home3",

        component: () =>
          import(/* webpackChunkName: "home2" */ "../views/home2.vue"),
        meta: {
          title: "Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/pay/:id",
        name: "pay",

        component: () =>
          import(/* webpackChunkName: "pay" */ "../views/pay.vue"),
        meta: {
          title: "Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/payme/:id",
        name: "payme",

        component: () =>
          import(/* webpackChunkName: "payme" */ "../views/pay-page.vue"),
        meta: {
          title: "Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/download",
        name: "download",

        component: () =>
          import(/* webpackChunkName: "download" */ "../views/download.vue"),
        meta: {
          title: "Download | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/rateus",
        name: "rateus",

        component: () =>
          import(/* webpackChunkName: "download" */ "../views/rateus.vue"),
        meta: {
          title: "Rate us | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/contact",
        name: "contact",

        component: () =>
          import(/* webpackChunkName: "download" */ "../views/contact-us.vue"),
        meta: {
          title: "Contact us | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/why/:id?",
        name: "why",

        component: () =>
          import(/* webpackChunkName: "why" */ "../views/why.vue"),
        meta: {
          title: "Why choose Us | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/what/:id?",
        name: "what",

        component: () =>
          import(/* webpackChunkName: "what" */ "../views/what.vue"),
        meta: {
          title: "What we do | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/request-access",
        name: "requestAccess",

        component: () =>
          import(
            /* webpackChunkName: "request-access" */ "../views/requestAccess.vue"
          ),
        meta: {
          title:
            "Request access to super payment | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/terms-and-conditions",
        name: "terms",

        component: () =>
          import(/* webpackChunkName: "terms" */ "../views/terms.vue"),
        meta: {
          title: "Terms & Conditions | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/privacy",
        name: "privacy",

        component: () =>
          import(/* webpackChunkName: "privacy" */ "../views/privacy.vue"),
        meta: {
          title: "Privacy Policy | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/legal",
        name: "legal",

        component: () =>
          import(/* webpackChunkName: "legal" */ "../views/legal.vue"),
        meta: {
          title: "Legal | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/gc/:redeemCode",
        name: "giftcard",

        component: () =>
          import(/* webpackChunkName: "giftcard" */ "../views/giftcard.vue"),
        meta: {
          title: "Redeem Gift Card | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/ticket/:eventid",
        name: "eticket",

        component: () =>
          import(/* webpackChunkName: "eticket" */ "../views/eticket.vue"),
        meta: {
          title: "Event Ticket | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/veremail/:token",
        name: "veremail",

        component: () =>
          import(
            /* webpackChunkName: "veremail" */ "../views/verify-email.vue"
          ),
        meta: {
          title: "Verify Email | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/sos",
        name: "sos",
        component: () =>
          import(/* webpackChunkName: "sos" */ "../views/sos.vue"),
        meta: {
          title: "SOS | Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/ke",
        name: "ke",
        component: () =>
          import(/* webpackChunkName: "kenya" */ "../views/ke/kenya.vue"),
        meta: {
          title: "Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: "/ng",
        name: "ng",
        component: () =>
          import(/* webpackChunkName: "ng" */ "../views/home/home.vue"),
        meta: {
          title: "Reliable way to pay and get paid - 1app",
        },
      },
      {
        path: ":country?",
        name: "home",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/beta-form/beta-form.vue"
          ),
        meta: {
          title: "Reliable way to pay and get paid - 1app",
        },
        beforeEnter: (to, from, next) => {
          const exist = countries.find(
            (e) => e.alpha3?.toLowerCase() == to.params.country
          );
          if (!exist) {
            next({ name: "pay-me", params: { username: to.params.country } });
          }
          next();
        },
      },
    ],
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/index.vue"),
  },
  {
    path: ":username",
    name: "pay-me",
    component: () => import(/* webpackChunkName: "pay-me" */ "../views/pay-me"),
    meta: {
      title: "1app: Everything Payment",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
